import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "display-flex justify-content-space-between" }
const _hoisted_2 = { class: "font-size12px margin-right10px" }
const _hoisted_3 = {
  type: "file",
  ref: "fileRef",
  accept: ".zip",
  style: {"display":"none"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    title: _ctx.$wordList.ProperAllTextImportFaceData,
    footerType: "customize",
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    footer: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", null, [
          _createVNode(_component_el_button, {
            class: "margin-right10px",
            onClick: _ctx.chooseFile
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextChooseAFile), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode("label", _hoisted_2, _toDisplayString(_ctx.chooseFileName), 1)
        ]),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _ctx.submit,
          disabled: _ctx.chooseFileName === ''
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$wordList.RDeiviceImport), 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_Fragment, null, _renderList(5, (index) => {
        return _createVNode("p", {
          key: index,
          class: "padding5px break-word"
        }, _toDisplayString(_ctx.$wordList['ImportFaceNo'+index]), 1)
      }), 64)),
      _createVNode("input", _hoisted_3, null, 512)
    ]),
    _: 1
  }, 8, ["title"]))
}