
import {
    defineComponent, ref, onMounted, nextTick
} from 'vue';
import Notice from '@/util/notice.base';
import HttpRequest from '@/util/axios.config';
import importFaceTipUtil from '@/components/view/pm/import-face-tip/importFaceTipUtil';
import { community } from '@/data';

export default defineComponent({
    setup(props, { emit }) {
        // v6.5 选择文件 2022/07/30 zyc
        const fileRef = ref();
        const chooseFileName = ref('');

        function chooseFile() {
            fileRef.value.click();
        }

        // 监听文件变化进行判断
        onMounted(() => {
            nextTick(() => {
                fileRef.value.addEventListener('change', () => {
                    const { size } = fileRef.value.files[0];
                    if (size > 200 * 1024 * 1024) {
                        fileRef.value.value = '';
                        Notice.message(WordList.ProperAllTextFileNoMare.format('200'), 'error');
                        return false;
                    }

                    chooseFileName.value = fileRef.value.files[0].name;
                    if (!/.zip$/.test(chooseFileName.value)) {
                        fileRef.value.value = '';
                        chooseFileName.value = '';
                        Notice.message(WordList.PropertyAllTextZipFile, 'error');
                    }

                    return true;
                });
            });
        });

        // 获取全局展示控制标志 2022/7/30 zyc
        const {
            pushFaceTackId, getImportDetails, clearGetFaceTaskListInterval
        } = importFaceTipUtil();

        function submit() {
            const form = new FormData();
            form.append('FaceFile', fileRef.value.files[0]);
            HttpRequest.post(`v3/web/${community.ProjectType.value}/face/import`, form, [(res: { data: { taskID: string } }) => {
                // 添加新任务ID到查询ids中
                pushFaceTackId(res.data.taskID);
                // 清空定时器
                clearGetFaceTaskListInterval();
                getImportDetails();
                emit('success');
                emit('close');
            }, false]);
        }

        return {
            fileRef,
            chooseFile,
            chooseFileName,
            submit
        };
    }
});
