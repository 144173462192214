import { ref, reactive, watch } from 'vue';
import HttpRequest from '@/util/axios.config';
import eventBus from '@/util/use-event-bus/global-event-bus';
import { ImportDetail } from './types';

// 是否展示人脸导入任务框
const isShowImportFaceTip = ref(false);

// 定时获取导入详情
const interval = ref<any>(0);
// 定时获取导入用户详情
const intervalResident = ref<any>(0);

// 保存导入数据详情
const importDetails: {
    [key in 'face' | 'resident']: ImportDetail;
} = reactive({
    face: {
        List: [],
        Finished: 0,
        CompletedTaskNum: '0',
        Total: '0'
    },
    resident: {
        List: [],
        Finished: 0,
        CompletedTaskNum: '0',
        Total: '0'
    }
});
const dealImportDetails: ImportDetail = reactive({
    List: [],
    Finished: 0,
    CompletedTaskNum: '0',
    Total: '0'
});

export default function importFaceTipControl() {
    // 清空定时器
    function clearGetFaceTaskListInterval() {
        clearInterval(interval.value);
    }
    function clearGetResidentTaskListInterval() {
        clearInterval(intervalResident.value);
    }

    // 初始化数据
    function initImportFaceTipData() {
        isShowImportFaceTip.value = false;
        sessionStorage.setItem('faceTackIds', '');
        sessionStorage.setItem('ImportProjectTaskUUIDs', '');
        clearGetFaceTaskListInterval();
        clearGetResidentTaskListInterval();
    }

    // 关闭进度调弹窗
    function closeImportFaceTip() {
        clearGetFaceTaskListInterval();
        initImportFaceTipData();
        isShowImportFaceTip.value = false;
    }

    // sessionStorage faceTackIds
    function getFaceTackIds() {
        const faceTackIds = sessionStorage.getItem('faceTackIds');
        if (faceTackIds) {
            return faceTackIds.split(',');
        }
        return [];
    }

    // 添加请求得任务ID
    function pushFaceTackId(tackId: string) {
        const faceTackIds = getFaceTackIds();
        if (!faceTackIds.includes(tackId)) {
            faceTackIds.push(tackId);
            sessionStorage.setItem('faceTackIds', faceTackIds.join(','));
        }
    }

    // 获取人脸导入进度条
    const getImportDetails = () => {
        HttpRequest.get(
            'v3/web/community/face/getFaceTaskList',
            [{ TaskIDs: sessionStorage.getItem('faceTackIds') }, false],
            (
                res: {
                    data: ImportDetail;
                }
            ) => {
                importDetails.face.List = res.data.List;
                importDetails.face.Finished = res.data.Finished;
                importDetails.face.CompletedTaskNum = res.data.CompletedTaskNum;
                importDetails.face.Total = res.data.Total;
                // 如果有返回数据就展示
                if (importDetails.face.List.length > 0) {
                    importDetails.face.List.forEach((item) => {
                        pushFaceTackId(item.ID);
                    });
                }

                if (importDetails.face.List.length === 0
                || importDetails.face.Finished === 1
                || importDetails.face.CompletedTaskNum === importDetails.face.Total) {
                    clearGetFaceTaskListInterval();
                } else {
                    importDetails.face.List.forEach((item) => {
                        pushFaceTackId(item.ID);
                    });
                    isShowImportFaceTip.value = true;
                    interval.value = setTimeout(() => {
                        getImportDetails();
                    }, 2000);
                }
            }
        );
    };

    // 获取存储的 ImportProjectTaskUUIDs
    function getImportProjectTaskUUIDs() {
        const importProjectTaskUUIDs = sessionStorage.getItem('ImportProjectTaskUUIDs');
        if (importProjectTaskUUIDs) {
            return importProjectTaskUUIDs.split(',');
        }
        return [];
    }

    // 添加请求得任务ID
    function pushImportProjectTaskUUIDs(tackId: string) {
        const importProjectTaskUUIDs = getImportProjectTaskUUIDs();
        if (!importProjectTaskUUIDs.includes(tackId)) {
            importProjectTaskUUIDs.push(tackId);
            sessionStorage.setItem('ImportProjectTaskUUIDs', importProjectTaskUUIDs.join(','));
        }
    }

    const getImportResidentDetail = () => {
        HttpRequest.get(
            'v3/web/community/resident/getImportDetails',
            [{ ImportProjectTaskUUIDs: sessionStorage.getItem('ImportProjectTaskUUIDs') }, false],
            (
                res: { data: ImportDetail }
            ) => {
                importDetails.resident.List = res.data.List;
                importDetails.resident.Finished = res.data.Finished;
                importDetails.resident.CompletedTaskNum = res.data.CompletedTaskNum;
                importDetails.resident.Total = res.data.Total;
                // 如果有返回数据就展示
                if (importDetails.resident.List.length > 0) {
                    importDetails.resident.List.forEach((item) => {
                        pushImportProjectTaskUUIDs(item.ID);
                        if (Number(item.Progress) === 100 && item.Status === '1') {
                            eventBus.emit('refreshResident');
                        }
                    });
                }

                if (importDetails.resident.List.length === 0
                    || importDetails.resident.Finished === 1
                    || importDetails.resident.CompletedTaskNum === importDetails.resident.Total) {
                    clearGetResidentTaskListInterval();
                } else {
                    importDetails.resident.List.forEach((item) => {
                        pushImportProjectTaskUUIDs(item.ID);
                    });
                    isShowImportFaceTip.value = true;
                    intervalResident.value = setTimeout(() => {
                        getImportResidentDetail();
                    }, 2000);
                }
            }
        );
    };

    watch(importDetails, () => {
        isShowImportFaceTip.value = (importDetails.face.List.length + importDetails.resident.List.length) !== 0;
        dealImportDetails.CompletedTaskNum = `${Number(importDetails.face.CompletedTaskNum) + Number(importDetails.resident.CompletedTaskNum)}`;
        dealImportDetails.Total = `${Number(importDetails.face.Total) + Number(importDetails.resident.Total)}`;
        // 区分类型
        const tempImportFaceDetails: (ImportDetail['List'][0] & {Type: string})[] = [];
        importDetails.face.List.forEach((item) => {
            tempImportFaceDetails.push({
                ...item,
                Type: 'face'
            });
        });
        const tempImportResidentDetails: (ImportDetail['List'][0] & {Type: string})[] = [];
        importDetails.resident.List.forEach((item) => {
            tempImportResidentDetails.push({
                ...item,
                Type: 'resident'
            });
        });
        dealImportDetails.List = tempImportFaceDetails.concat(tempImportResidentDetails);
        // 按创建时间排序
        dealImportDetails.List.sort((detail1, detail2) => detail2.CreateTime - detail1.CreateTime);
    }, {
        deep: true
    });

    return {
        dealImportDetails,
        isShowImportFaceTip,
        clearGetFaceTaskListInterval,
        getImportDetails,
        closeImportFaceTip,
        pushFaceTackId,
        initImportFaceTipData,
        getImportResidentDetail,
        pushImportProjectTaskUUIDs,
        clearGetResidentTaskListInterval
    };
}