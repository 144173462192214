
import { ref, defineComponent } from 'vue';
import { ElMessageBox } from 'element-plus';
import HttpRequest from '@/util/axios.config';
import importFaceTipUtil from '@/components/view/pm/import-face-tip/importFaceTipUtil';
import ImportFaceDialog from '@/components/view/pm/import-face-plus/components/import-face-dialog.vue';
import Notice from '@/util/notice.base';
import { downloadFileHandler } from '@/util/system';

export default defineComponent({
    components: {
        ImportFaceDialog
    },
    setup() {
        const batchAddRef = ref();
        const batchEditRef = ref();
        const rfCardRef = ref();
        const isShowImportDialog = ref(false);
        const isShowImportFaceDialog = ref(false);

        const {
            pushImportProjectTaskUUIDs,
            clearGetResidentTaskListInterval,
            getImportResidentDetail
        } = importFaceTipUtil();

        const setBatchAddFile = () => {
            batchAddRef.value.click();
        };

        const setBatchEditFile = () => {
            batchEditRef.value.click();
        };

        const setRFCardFile = () => {
            rfCardRef.value.click();
        };

        // 批量新增住户
        const batchAddResident = () => {
            const formData = new FormData();
            formData.append('Resident', batchAddRef.value.files[0]);
            HttpRequest.post('v3/web/community/resident/import', formData, [(res: { data: { ImportProjectTaskUUID: string } }) => {
                // 添加新任务ID到查询ids中
                pushImportProjectTaskUUIDs(res.data.ImportProjectTaskUUID);
                // 清空定时器
                clearGetResidentTaskListInterval();
                getImportResidentDetail();
                isShowImportDialog.value = false;
            }, false]);
            batchAddRef.value.value = '';
        };

        // 批量编辑住户
        const batchEditResident = () => {
            const formData = new FormData();
            formData.append('Resident', batchEditRef.value.files[0]);
            HttpRequest.post('v3/web/community/resident/importBatchEdit', formData, [(res: { data: { ImportProjectTaskUUID: string } }) => {
                // 添加新任务ID到查询ids中
                pushImportProjectTaskUUIDs(res.data.ImportProjectTaskUUID);
                // 清空定时器
                clearGetResidentTaskListInterval();
                getImportResidentDetail();
                isShowImportDialog.value = false;
            }, false]);
            batchEditRef.value.value = '';
        };

        // 按筛选导出住户
        const exportResident = () => {
            downloadFileHandler(`${HttpRequest.host}web-server/v3/web/community/resident/export`);
        };
        // 下载模板
        const downloadTemplate = (url: string) => {
            downloadFileHandler(url);
        };

        const importRFCardAndPin = () => {
            const formData = new FormData();
            formData.append('file', rfCardRef.value.files[0]);
            const url = 'v3/web/community/key/importUserRfCardAndPin';
            HttpRequest.post(url, formData, () => {
                isShowImportDialog.value = false;
            }, [(res: {msg: string|string[]}) => {
                if (typeof res.msg === 'string') {
                    Notice.messageBox('alert', res.msg, WordList.TabelMessageBoxAlertRequireFailed, 'error')(() => false);
                } else {
                    let str = '';

                    res.msg.forEach((text) => {
                        str += `<p>${text}</p>`;
                    });
                    console.log(str);
                    ElMessageBox.alert(
                        str,
                        WordList.TabelMessageBoxAlertRequireFailed,
                        {
                            type: 'error',
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: WordList.TabelFootSure
                        }
                    );
                }
            }, false]);
            rfCardRef.value.value = '';
        };

        return {
            batchAddRef,
            batchEditRef,
            rfCardRef,
            isShowImportDialog,
            isShowImportFaceDialog,
            setBatchAddFile,
            setBatchEditFile,
            setRFCardFile,
            batchAddResident,
            batchEditResident,
            exportResident,
            importRFCardAndPin,
            downloadTemplate
        };
    }
});
